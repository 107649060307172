@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}


.primary_btn {
     @apply  bg-primaryC text-whiteC font-medium uppercase  
                text-[10px]  rounded-[22px] px-[24px] py-[16px]
                sm:tracking-wide
                sm:text-[12px]  sm:rounded-[22px] sm:px-[16px] sm:py-[10px]
                md:text-[14px]  md:rounded-[32px] md:px-[24px] md:py-[15px]
                lg:text-[14px]  lg:rounded-[44px] lg:px-[24px] lg:py-[16px]  
                xl:text-[14px]  xl:rounded-[44px] xl:px-[32px] xl:py-[18px]  
}

.secoundry_btn {
    @apply bg-whiteC text-primaryC font-medium uppercase 
                text-[10px]  rounded-[22px]  px-[24px] py-[16px]
                sm:tracking-wide
                sm:text-[12px]  sm:rounded-[22px] sm:px-[16px] sm:py-[10px]
                md:text-[14px]  md:rounded-[32px] md:px-[24px] md:py-[15px]
                lg:text-[14px]  lg:rounded-[44px] lg:px-[24px] lg:py-[16px]  
                xl:text-[14px]  xl:rounded-[44px] xl:px-[32px] xl:py-[18px]  
}

.content_btn {
    @apply bg-contentC text-whiteC uppercase font-medium 
                text-[10px] rounded-[22px] px-[24px] py-[16px]
                sm:tracking-wide
                sm:text-[12px]  sm:rounded-[22px] sm:px-[24px] sm:py-[16px]
                lg:text-[14px]  lg:rounded-[44px] lg:px-[24px] lg:py-[16px]  
                xl:text-[14px]  xl:rounded-[44px] xl:px-[32px] xl:py-[18px]  

}


  .transparent_btn {
    @apply bg-transparent text-whiteC uppercase border border-solid border-whiteC font-normal 
           hover:bg-gray-200 hover:bg-opacity-20 hover:shadow-lg hover:scale-105  transition-colors duration-300
           text-[10px] rounded-[22px] px-[12px] py-[6px]
                sm:tracking-wide    
                sm:text-[10px] sm:rounded-[160px] sm:px-[10px] 
                md:text-[12px] md:rounded-[180px] md:px-[15px] 
                lg:text-[16px] lg:rounded-[200px] lg:px-[16px] lg:py-[6px]
}


.animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(40, end) 1 ;
}




/* BASIC */
/* 
h1 - text-[32px];
h2 - text-[24px];
h3 - text-[20px];
h4 - text-[18px];
h5 - text-[16px];

title1 - text-[14px];
title2 - text-[12px];

Body - text-[10px];

Caption1 - text-[8px];
Caption2 - text-[6px];
*/

/* SMALL - SM */

/* 
h1 - text-[48px];
h2 - text-[36px];
h3 - text-[30px];
h4 - text-[24px];
h5 - text-[20px];

title1 - text-[18px];
title2 - text-[16px];

Body - text-[14px];

Caption1 - text-[10px];
Caption2 - text-[8px];
*/

/* MEDIUM -  MD */

/* 
h1 - text-[64px];
h2 - text-[40px];
h3 - text-[32px];
h4 - text-[28px];
h5 - text-[24px];

title1 - text-[20px];
title2 - text-[18px];

Body - text-[16px];

Caption1 - text-[12px];
Caption2 - text-[10px];
*/


/* MM  */

/* 
h1 - text-[96px];
h2 - text-[36px];
h3 - text-[30px];
h4 - text-[25px];
h5 - text-[21px];

title1 - text-[21px];
title2 - text-[19px];

Body - text-[16px];

Caption1 - text-[12px];
Caption2 - text-[10px];
*/


/* LARGE - LG */

/* 
h1 - text-[128px];
h2 - text-[48px];
h3 - text-[40px];
h4 - text-[33px];
h5 - text-[28px];

title1 - text-[23px];
title2 - text-[19px];

Body - text-[16px];

Caption1 - text-[13px];
Caption2 - text-[11px];
*/

