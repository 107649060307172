@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.auto-scroll-slider {
  display: flex;
  width: max-content;
  animation: scroll 30s linear infinite;
  animation-play-state: running; /* Ensure the animation is running by default */
}

.auto-scroll-slider:hover {
  animation-play-state: paused; /* Pause the animation on hover */

}



@keyframes typing {
  from {
    width: 0;
  }
  to {
    width : 100%;
  }



}

